<template>
  <div>
      <v-btn
              color="primary"
              dark
              transition="slide-x-transition"
              @click="dialog=true"
      >
          LEADS
      </v-btn>
      <v-dialog
              v-model="dialog"
      >
          <v-card>
              <leads-card
                      :domain-name="domainName"
                      :reload="true"
              />
              <v-card-actions>
                  <v-btn
                          color="primary"
                          text
                          @click="dialog = false"
                  >
                      Close
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>
<script>
import DomainDetailsCard from "@/components/domains/DomainDetailsCard.vue";
import LeadsCard from "./LeadsCard.vue"

export default {
    components: {LeadsCard},
    props: ["domainName"],
    data: () => ({
        dialog: false,
    }),
}
</script>
