<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          tile
          v-on="on"
          x-small
          class="mb-1"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>

      <v-card-text>

        <v-text-field
            v-model="phone"
            label="Phone"
        />
        <v-text-field
            v-model="email"
            label="Email"
        />
        <v-text-field
            v-model="contactName"
            label="Name"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="blue darken-1"
            text
            @click="list"
        >
          Send
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="menu = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: ["conversationId"],
  data: () => ({
    menu: false,
    phone: '',
    email: '',
    contactName: '',
    sibling: ''
  }),

  computed: {
    ...mapGetters("domains", ["getDomainByName"]),

    ...mapGetters({
      conversations: "conversations/getConversationsByDomainName",
    }),


  },

  methods: {
    ...mapActions("conversations", ["newConversationAction"]),

    list() {
      const conversation = {
        id: this.conversationId,
        lead:
            {

              phone: this.phone,
              email: this.email,
              contactName: this.contactName
            }
      }
      this.newConversationAction(conversation);
    },


  },

};
</script>

<style></style>
