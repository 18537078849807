<template>
  <v-btn @click="loadNotAnswered"> Load Not Answered </v-btn>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'LazyMessageLoader',
  methods: {
    ...mapActions("messages", ["loadNotAnswered"]),
  },
  mounted() {
    this.loadNotAnswered();
    // window.onscroll = () => {
    //   const el = document.documentElement
    //   const isBottomOfScreen =
    //       el.scrollTop + window.innerHeight >= el.offsetHeight
    //   if (isBottomOfScreen) {
    //     this.loadNotRead();
    //   }
    // }
  },
  computed:{
    ...mapState("messages", ["currentPage"]),
  },
  beforeDestroy() {
    window.onscroll = null
  },
}
</script>

<style scoped>

</style>
